import { ReactComponent as ReactLogoIcon } from '../assets/images/logo.svg'

const NavbarComponent = () => {
    const email = localStorage.getItem("userEmail");

    return (
        <header className="header header-6">
            <div className="navbar-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <ReactLogoIcon/>
                                </a>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent6"
                                        aria-controls="navbarSupportedContent6" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse sub-menu-bar"
                                     id="navbarSupportedContent6">
                                    <ul id="nav6" className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <a className="page-scroll" href="/">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="/contacts">Contacts</a>
                                        </li>

                                        {!email && <li className="nav-item">
                                            <a className="page-scroll " href="/sign-in">Sign In</a>
                                        </li>}

                                        {!email && <li className="nav-item">
                                            <a className="page-scroll " href="/sign-up">Sign Up</a>
                                        </li>}

                                        {email && <li className="nav-item">
                                            <a className="page-scroll " href="/account">{email}</a>
                                        </li>}

                                        {email && <li className="nav-item">
                                            <a className="page-scroll" href="#"
                                               onClick={() => {
                                                   localStorage.removeItem("userEmail");
                                                   window.location.href = '/';
                                               }}
                                            >Logout</a>
                                        </li>}
                                    </ul>
                                </div>
                            </nav>

                        </div>
                    </div>

                </div>

            </div>

        </header>
    );
}

export default NavbarComponent;