import MetaTags from 'react-meta-tags'
import * as Yup from "yup";
import {useState} from "react";
import {Field, Form, Formik} from "formik";

const ContactSchema = Yup.object().shape({
    name: Yup.string().required('This field must be filled out'),
    number: Yup.string().required('This field must be filled out'),
    subject: Yup.string().required('This field must be filled out'),
    email: Yup.string().email('Email is not valid').required('This field must be filled out'),
});

const ContactsPage = () => {
    const [success, setSuccess] = useState(false);

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        setSuccess(true)
    };

    return (
        <>
            <MetaTags>
                <title>Contact - MonCam Cloud Storage</title>
            </MetaTags>

            <section id="contact" className="contact-section contact-style-3 hero-section-wrapper-5">
                <div className="hero-section hero-style-5 img-bg">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-10">
                                <div className="section-title text-center mb-50">
                                    <h3 className="mb-15">Get in touch</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-form-wrapper">
                                    {!success && <Formik
                                        initialValues={{name: '', email: '', number: '', subject: ''}}
                                        validationSchema={ContactSchema}
                                        onSubmit={handleSubmit}
                                    >
                                        {({isSubmitting, errors, touched}) => (
                                            <Form>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="single-input">
                                                            <Field
                                                                className='form-custom'
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                placeholder="Name"
                                                            />
                                                            <i className="lni lni-user"></i>
                                                            {errors.name && touched.name ? (
                                                                <div
                                                                    className='error'>{errors.name}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="single-input">
                                                            <Field
                                                                className='form-custom'
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                placeholder="Email"
                                                            />
                                                            <i className="lni lni-envelope"></i>
                                                            {errors.email && touched.email ? (
                                                                <div
                                                                    className='error'>{errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="single-input">
                                                            <Field
                                                                className='form-custom'
                                                                type="text"
                                                                name="number"
                                                                id="number"
                                                                placeholder="Number"
                                                            />
                                                            <i className="lni lni-phone"></i>
                                                            {errors.number && touched.number ? (
                                                                <div
                                                                    className='error'>{errors.number}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="single-input">
                                                            <Field
                                                                className='form-custom'
                                                                type="text"
                                                                name="subject"
                                                                id="subject"
                                                                placeholder="Subject"
                                                            />
                                                            <i className="lni lni-text-format"></i>
                                                            {errors.subject && touched.subject ? (
                                                                <div
                                                                    className='error'>{errors.subject}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="single-input">
                                                    <textarea name="message" id="message" className="form-input"
                                                              placeholder="Message" rows="6"></textarea>
                                                            <i className="lni lni-comments-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-button">
                                                            <button type="submit" className="button"><i
                                                                className="lni lni-telegram-original"></i> Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>}

                                    {success && (
                                        <div className='feature-style-5 p-0'>
                                            <div className='single-feature wow fadeInUp h-100'>
                                                <p className="py-5 text-center">
                                                    We will review your request and get back to you shortly
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                            <div className="col-lg-4">
                                <div className="left-wrapper">
                                    <div className="row">

                                        <div className="col-lg-12 col-md-6">
                                            <div className="single-item">
                                                <div className="icon">
                                                    <i className="lni lni-envelope"></i>
                                                </div>
                                                <div className="text">
                                                    <p>
                                                        <a href="mailto:support@moncam.net">support@moncam.net</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6">
                                            <div className="single-item">
                                                <div className="icon">
                                                    <i className="lni lni-map-marker"></i>
                                                </div>
                                                <div className="text">
                                                    <p><strong>Street address</strong></p>
                                                  <p>Plot 838, Namanve Industrial Park Mukono, Uganda</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="feature" className="feature-section feature-style-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-8">
                            <div className="section-title text-center mb-60">
                                <h3 className="mb-15 wow fadeInUp" data-wow-delay=".2s">
                                    The Pinnacle Cloud Storage Solution
                                </h3>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                    Embrace simplicity in video surveillance with our comprehensive cloud storage
                                    solution. Our app offers a unified platform for monitoring, eliminating the need for
                                    multiple devices or intricate configurations, regardless of your surveillance
                                    equipment's brand.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 d-flex flex-wrap">
                        <div className="col h-100">
                            <div className="single-feature h-100">
                                <div className="icon">
                                    <i className="lni lni-protection"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Confident Control</h5>
                                    <p>
                                        Ensuring Security and Privacy with Secure Control of Your System
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="col h-100">
                            <div className="single-feature h-100">
                                <div className="icon">
                                    <i className="lni lni-cloud-sync"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Smooth Teamwork</h5>
                                    <p>Collaborate Effortlessly with Cloud Video Broadcasting for Teams</p>
                                </div>
                            </div>
                        </div>
                        <div className="col h-100">
                            <div className="single-feature wow fadeInUp h-100" data-wow-delay=".6s">
                                <div className="icon">
                                    <i className="lni lni-alarm"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Timely Awareness</h5>
                                    <p>Receive Instant Alert Notifications for Real-time Awareness</p>
                                </div>
                            </div>
                        </div>
                        <div className="col h-100">
                            <div className="single-feature h-100 " data-wow-delay=".4s">
                                <div className="icon">
                                    <i className="lni lni-calendar"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Extended Tranquility</h5>
                                    <p>Two-Year Extended Assurance for Cloud Storage for Long-Term Peace of Mind</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ContactsPage;