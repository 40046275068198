import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useState} from "react";

const ContactSchema = Yup.object().shape({
    ip_address: Yup.string().required('This field must be filled out'),
    port: Yup.string().required('This field must be filled out'),
    user: Yup.string().required('This field must be filled out'),
    password: Yup.string().required('This field must be filled out'),
});

const ProfilePage = () => {
    const [error, setError] = useState();
    const [save, setSave] = useState(false);

    const [devices, setDevices] = useState(
        JSON.parse(localStorage.getItem("devices")) || []
    );

    const userEmail = localStorage.getItem("userEmail") || {}
    const userPassword = localStorage.getItem("userPassword") || {}

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        const {index, ...deviceData} = values;
        const updatedDevices = [...devices];
        updatedDevices[index] = deviceData;

        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
        setSave(true);
        setSave(true);
        setSubmitting(false);
    };

    const handleDeleteDevice = (index) => {
        const updatedDevices = devices.filter((_, i) => i !== index);
        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
    };

    const handleAddEmptyDevice = () => {
        if (devices.length < 4) {
            const emptyDevice = {
                ip_address: "",
                port: "",
                user: "",
                password: "",
            };
            setDevices([...devices, emptyDevice]);
        } else {
            setError("You can add a maximum of 4 devices.");
        }
    };

    return (
        <>
            <MetaTags>
                <title>Profile</title>
            </MetaTags>

            <section id="contact" className="contact-section contact-style-3 hero-section-wrapper-5">
                <div className="container feature-style-5 bg-transparent py-0">
                    <div className="row my-5">
                        <div className="col-12 col-lg-6 m-auto">
                            <div className="single-feature wow fadeInUp h-100 bg-white">
                                <h1 className="fw-bold mb-3">Account</h1>
                                <div className="mb-3">
                                    <input id="outlined-basic"
                                           placeholder="Email"
                                           className='form-custom'
                                           value={userEmail}
                                           disabled
                                    />
                                </div>
                                <div className="mb-3">
                                    <input id="outlined-basic"
                                           className='form-custom'
                                           type="password"
                                           value={userPassword}
                                           disabled
                                    />
                                </div>
                            </div>
                            <button className="button button-lg radius-50 wow fadeInUp w-100"
                               onClick={handleAddEmptyDevice}>
                                Add Device
                            </button>

                            <div className="text-center">
                                {error && <p className="mt-2 mb-0 text-danger mb-3">
                                    {error}
                                </p>}
                            </div>
                        </div>
                    </div>

                    {save &&
                        <div
                            className="single-feature wow fadeInUp h-100 bg-white mb-3 mb-0 p-2 text-center"
                            role="alert">
                            Your data has been saved
                        </div>}
                    <div className="row my-5">
                        <div className="col-12 col-lg-12">
                            <div className=" w-100">
                                <div>
                                    <div className="row row-cols-1 g-3">
                                        {devices.map((device, index) => (
                                            <div className="col mb-3" key={index}>
                                                <div className="single-feature wow fadeInUp h-100 bg-white">
                                                    <div className="card-body">
                                                        <Formik
                                                            initialValues={{
                                                                index: index,
                                                                ip_address: device?.ip_address || '',
                                                                port: device?.port || '',
                                                                user: device?.user || '',
                                                                password: device?.password || '',
                                                            }}
                                                            validationSchema={ContactSchema}
                                                            onSubmit={handleSubmit}
                                                            enableReinitialize={true}
                                                        >
                                                            {({isSubmitting, errors, touched}) => (
                                                                <Form>
                                                                    <div
                                                                        className="row d-flex align-items-center g-3 mb-4">
                                                                        <div className="col-6 m-auto text-center mt-4">
                                                                            <button className='btn btn-danger'
                                                                               onClick={() => handleDeleteDevice(index)}>
                                                                                <i className="fa-solid fa-trash cursor-pointer"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <Field
                                                                        type="hidden"
                                                                        name="index"
                                                                        id="index"
                                                                        value={index}
                                                                    />

                                                                    <div className="row row-cols-5 g-3">

                                                                        <div className="col">
                                                                            <Field
                                                                                className='form-custom'
                                                                                fullWidth
                                                                                type="text"
                                                                                name="ip_address"
                                                                                id="ip_address"
                                                                                placeholder="IP address"
                                                                            />
                                                                            {errors.ip_address && touched.ip_address ? (
                                                                                <div
                                                                                    className='error'>{errors.ip_address}</div>
                                                                            ) : null}
                                                                        </div>


                                                                        <div className="col">
                                                                            <Field
                                                                                className='form-custom'
                                                                                fullWidth
                                                                                type="text"
                                                                                name="port"
                                                                                id="port"
                                                                                placeholder="Port"
                                                                            />
                                                                            {errors.port && touched.port ? (
                                                                                <div
                                                                                    className='error'>{errors.port}</div>
                                                                            ) : null}
                                                                        </div>


                                                                    <div className="col">
                                                                        <Field
                                                                            className='form-custom'
                                                                            fullWidth
                                                                            type="text"
                                                                            name="user"
                                                                            id="user"
                                                                            placeholder="User"
                                                                        />
                                                                        {errors.user && touched.user ? (
                                                                            <div className='error'>{errors.user}</div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="col">
                                                                        <Field
                                                                            className='form-custom'
                                                                            fullWidth
                                                                            type="password"
                                                                            name="password"
                                                                            id="password"
                                                                            placeholder="Password"
                                                                        />
                                                                        {errors.password && touched.password ? (
                                                                            <div
                                                                                className='error'>{errors.password}</div>
                                                                        ) : null}
                                                                    </div>

                                                                        <div className="col">
                                                                            <button
                                                                                className="button button-lg radius-10 wow fadeInUp w-100"
                                                                                type="submit"
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                {isSubmitting ? "..." : "Save"}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Form>
                                                            )}
                                                        </Formik>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default ProfilePage;