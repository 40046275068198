import './App.scss'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Layout from "./components/layout";
import HomePage from "./pages/home";
import TermsOfUsePage from "./pages/terms-of-use";
import PrivacyPolicyPage from "./pages/privacy-policy";
import NotFound from "./pages/not-found";
import LoginPage from "./pages/login";
import RegistrationPage from "./pages/registration";
import ContactsPage from "./pages/contacts";
import ProfilePage from "./pages/profile";

function App() {
    return (
        <Router>
            <Routes>
                <Route element={<Layout/>}>
                    <Route exact path='/' element={<HomePage/>}/>
                    <Route exact path='/contacts' element={<ContactsPage/>}/>
                    <Route exact path='/account' element={<ProfilePage/>}/>
                    <Route exact path='/sign-in' element={<LoginPage/>}/>
                    <Route exact path='/sign-up' element={<RegistrationPage/>}/>
                    <Route exact path='/terms-of-use' element={<TermsOfUsePage/>}/>
                    <Route exact path='/privacy-policy' element={<PrivacyPolicyPage/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
