import MetaTags from "react-meta-tags";

const TermsOfUsePage = () => {
    return (
        <>
            <MetaTags>
                <title>MonCam Cloud - Terms of Use</title>
            </MetaTags>
            <section id="contact" className="contact-section contact-style-3 hero-section-wrapper-5">
                <div className="container feature-style-5 bg-transparent py-0">
                    <div className="single-feature wow fadeInUp h-100 bg-white text-start">
                        <h1 id="terms_info_title">Terms of Use</h1>

                        <p>The Service utilizes software that may automatically download and install updates provided by MonCam on your devices. These updates could include bug fixes, modified functions, updated software modules, or entirely new versions. By utilizing the Service, you implicitly consent to receiving and allowing the installation of such updates on your devices.</p>
                        <h2>Transactions</h2>
                        <p>If you wish to make a purchase through the Service ("Transaction"), you may be required to furnish specific information pertinent to your Transaction. This information may include, but is not limited to, your credit card number, credit card expiration date, billing address, and shipping details.</p>
                        <p>We reserve the right to reject or cancel your order at any time for reasons such as product or service unavailability, errors in the product or service description or pricing, errors in your order, or other considerations.</p>
                        <p>You affirm that: (i) you possess the legal right to use any credit card(s) or payment method(s) for the Transaction; and (ii) the information you provide is accurate, correct, and complete.</p>
                        <p>The Service may employ third-party services to facilitate payment. By submitting your information, you grant us the right to share it with these third parties as outlined in our Privacy Policy at <a href="https://moncam.net/privacy-policy/">privacy policy</a>.</p>
                        <p>We retain the right to reject or cancel your order in the event of suspected fraud or unauthorized/illegal transactions. For queries or concerns, please contact us via email at <a href="mailto:support@moncam.net">support@moncam.net</a>.</p>

                        <h2>Availability, Errors, and Inaccuracies</h2>
                        <p>We continuously update product and service offerings on the Service. However, there may be delays in updating information and advertising on other websites. Information on the Service may contain errors, inaccuracies, or be incomplete or outdated. Products or services may be mispriced, inaccurately described, or unavailable on the Service. We do not guarantee the accuracy or completeness of information on the Service.</p>
                        <p>Therefore, we reserve the right to change or update information and to correct errors, inaccuracies, or omissions without prior notice.</p>

                        <h2>Subscriptions</h2>
                        <p>Some aspects of the Service are billed on a subscription basis ("Subscriptions"). Billing occurs in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either monthly or annually, depending on the selected subscription plan. A valid payment method, including credit card or PayPal, is required to process payment for your balance to use your Subscription. By submitting payment information, you automatically authorize MonCam. to charge the confirmed amount from your credit card or PayPal through our payment gateway provider.</p>
                        <p>At the end of each Billing Cycle, your Subscription may automatically renew under the same conditions unless canceled by you or MonCam. You can cancel your Subscription renewal through your online account management page.</p>
                        <p>If automatic billing fails, MonCam. will issue an electronic invoice, requiring manual payment within a specified deadline.</p>

                        <h2>Subject</h2>
                        <p>The existing Service, as well as its development, expansion, or reduction, are the subjects of these Terms. MonCam offers the MonCam Storage Service, allowing you to view, record, and process video and audio data from private or public premises using MonCam-branded equipment. This Service offers online viewing/listening, recording of video and audio data, remote access to equipment and recorded information, downloading to computer or mobile devices, as well as motion detection and other related services detailed at <a href="http://moncam.net/">www.moncam.net</a></p>
                        <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you do not have permission to access the Service. Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms, applying to all visitors, users, and others accessing or using the Service.</p>

                        <h2>DMCA Notice and Copyright Infringement Procedure</h2>
                        <p>You can submit a Digital Millennium Copyright Act (DMCA) notification by providing our Copyright Agent with the required information in writing. For further detail, refer to 17 U.S.C 512(c)(3). Contact our Copyright Agent via email at <a href="mailto:support@moncam.net">support@moncam.net</a></p>

                        <h2>Free Trial</h2>
                        <p>MonCam. may, at its sole discretion, offer a Subscription with a free trial for a limited period ("Free Trial"). Even if you enter billing information during the Free Trial sign-up, you won't be charged by MonCam. until the Free Trial expires. To access paid services or Subscriptions, you must select and activate them after the Free Trial period.</p>
                        <p>At any time and without notice, MonCam. reserves the right to (i) modify Free Trial terms and conditions, or (ii) cancel the Free Trial offer. You may be required to enter billing information to sign up for the Free Trial.</p>

                        <h2>Refunds</h2>
                        <ul>
                            <li>Certain refund requests for Subscriptions may be considered by MonCam. on a case-by-case basis and granted at its sole discretion. For questions or concerns, contact us via email at <a href="mailto:support@moncam.net">support@moncam.net</a></li>
                        </ul>

                        <h2>Intellectual Property</h2>
                        <p>The Service and its original content, features, and functionality are and will remain the exclusive property of MonCam. and its licensors. The Service is protected by copyright, trademark, and other laws of the Republic of Tanzania and foreign countries. Our trademarks and trade dress may not be used without the prior written consent of MonCam.</p>

                        <h2>Accounts</h2>
                        <p>By creating an account, you guarantee that you are above 18 years old, and the information you provide is accurate, complete, and current. Inaccurate, incomplete, or obsolete information may result in immediate termination of your account. You are responsible for maintaining the confidentiality of your account and password, and agree to accept responsibility for all activities under your account, whether using our Service or a third-party service. Notify us immediately of any breach of security or unauthorized use of your account. Do not use offensive, vulgar, or obscene usernames. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders at our discretion. For questions or concerns, contact us via email at <a href="mailto:support@moncam.net">support@moncam.net</a></p>

                        <h2>Termination</h2>
                        <p>We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason and without limitation, including a breach of the Terms. To terminate your account, discontinue using the Service or contact us via email at <a href="mailto:support@moncam.net">support@moncam.net</a>.</p>
                        <p>Provisions such as ownership, warranty disclaimers, indemnity, and limitations of liability survive termination.</p>

                        <h2>Compliance With Laws</h2>
                        <p>We will disclose your Personal Information as required by law or subpoena, or if we believe it's necessary to comply with the law, law enforcement requests, or to protect our Service's security or integrity.</p>

                        <h2>Fee Changes</h2>
                        <p>MonCam., at its sole discretion, may modify Subscription fees for the Subscriptions. Changes become effective at the end of the current Billing Cycle. We provide reasonable prior notice of fee changes, and your continued use of the Service after the change constitutes agreement to pay the modified Subscription fee.</p>

                        <h2>Links To Other Websites</h2>
                        <p>Our Service may contain links to third-party websites or services not owned or controlled by MonCam.</p>
                        <p>MonCam. has no control over, and assumes no responsibility for, the content, privacy policies, or practices of third-party websites or services. We do not warrant the offerings of any entities/individuals or their websites. You acknowledge and agree that MonCam. is not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods, or services available on or through any third-party websites or services. We strongly advise reading the terms, conditions, and privacy policies of any third-party websites or services you visit.</p>

                        <h2>Governing Law</h2>
                        <p>These Terms are governed and construed in accordance with the laws of the Republic of Tanzania, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver. If any provision is held invalid or unenforceable by a court, the remaining provisions will remain in effect. These Terms constitute the entire agreement between us regarding the Service, superseding any prior agreements.</p>

                        <h2>Modifications</h2>
                        <p>We retain the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is substantial, we will give at least 30 days notice before the new terms take effect. The determination of what constitutes a substantial change rests solely with us.</p>
                        <p>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.</p>

                        <h2>Indemnification</h2>
                        <p>You agree to defend, indemnify, and hold harmless MonCam. and its licensee and licensors, as well as their employees, contractors, agents, officers, and directors, from any and all claims, damages, obligations, losses, liabilities, costs, or debts, including attorney's fees, resulting from or arising out of a) your use and access to the Service, by you or any person using your account and password, or b) a breach of these Terms.</p>

                        <h2>Limitation Of Liability</h2>
                        <p>In no event shall MonCam., its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
                        <p>By using our Service, you confirm that the liability for all information (such as audio files, video files, and other data) to which you have access as part of the Service or through the use of the Service shall be assumed by the person who provided or created the information.</p>

                        <h2>Disclaimer</h2>
                        <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>
                        <p>MonCam., its subsidiaries, affiliates, and licensors do not warrant that a) the Service will function uninterrupted, secure, or available at any particular time or location; b) any errors or defects will be corrected; or c) the results of using the Service will meet your requirements.</p>

                        <h2>Exclusions</h2>
                        <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>

                        <h2>Contact Information</h2>

                        <p className="mb-1">
                            <strong>Email: </strong>
                            <a href="mailto:support@moncam.net">support@moncam.net</a>
                        </p>

                        <p className="mb-0">
                            <strong>Street address:</strong> Plot 838, Namanve Industrial Park Mukono, Uganda
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsOfUsePage;