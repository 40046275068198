import MetaTags from "react-meta-tags";

const PrivacyPolicyPage = () => {
    return (
        <>
            <MetaTags>
                <title>MonCam Cloud - Privacy policy</title>
            </MetaTags>

            <section className="mt-5">
                <div className="container feature-style-5 bg-transparent py-0">
                    <div className="single-feature wow fadeInUp h-100 bg-white text-start">
                        <h1 id="privacy_info_title">Privacy policy</h1>

                        <div className="block-confidential-item">
                            <p>This section provides insights into our guidelines governing the acquisition,
                                utilization, and
                                revelation of Personal Data when engaging with our Platform.</p>
                            <p>We commit to using or revealing your data solely in accordance with this Confidentiality
                                Code.</p>
                            <p>By using the Platform, you assent to the collection and utilization of data as outlined
                                in this
                                Confidentiality Code. Unless otherwise defined in this Confidentiality Code, terms bear
                                the same
                                significance as in our Terms of Service, accessible at <a
                                    href="https://moncam.net/terms-of-use/">terms of use</a>.</p>

                            <h2>Data Collection And Utilization</h2>

                            <p>While using our Platform, we may request specific personally unidentifiable information
                                to reach
                                or recognize you. Personally unidentifiable information may incorporate, but is not
                                limited to,
                                your email address, name, phone number, and other details ("Personal Data"). We gather
                                this
                                information to deliver the Platform, recognize and communicate with you, reply to your
                                requests/inquiries, service your purchase orders, subscriptions, or payments, and
                                enhance our
                                services.
                            </p>

                            <h2>Recorded Data</h2>
                            <p>We may also gather information that your browser dispatches whenever you visit our
                                Platform
                                ("Recorded Data"). This Recorded Data may include information such as your computer's
                                Internet
                                Protocol ("IP") address, browser type, browser version, the pages of our Platform that
                                you visit,
                                the time and date of your visit, the time spent on those pages, and other statistics.
                            </p>
                            <p>In addition, we may use third-party services like Google Analytics that compile, observe,
                                and
                                analyze this type of information to boost our Platform's functionality. These
                                third-party service
                                providers have their own confidentiality policies governing how they utilize such
                                information.
                            </p>

                            <h2>Data Tokens</h2>
                            <p>Tokens are files with a small amount of encoded data, including an unknown unique
                                identifier.
                                Tokens are sent to your browser from a website and transmitted to your device. We employ
                                tokens
                                to compile data for the improvement of our services to you.
                            </p>
                            <p>You can guide your browser to reject all tokens or to signify when a token is being sent.
                                The Help
                                feature on most browsers offers information on how to accept, deactivate, or be informed
                                when
                                receiving a new token.
                            </p>
                            <p>If you decline tokens, you may be unable to use some features of our Platform, and we
                                propose
                                keeping them enabled.
                            </p>

                            <h2>No Tracking Disclosure</h2>

                            <p>We endorse No Tracking ("NT"). No Tracking is a preference you can establish in your web
                                browser
                                to notify websites that you do not wish to be traced.
                            </p>
                            <p>You can activate or deactivate No Tracking by visiting the Preferences or Settings page
                                of your
                                web browser.
                            </p>

                            <h2>Service Collaborators</h2>
                            <p>We may engage external entities and individuals to ease our Platform, offer the Platform
                                on our
                                behalf, execute Platform-related services, and/or aid us in analyzing how our Platform
                                is
                                utilized.
                            </p>
                            <p>These external parties have access to your Personal Data solely for the purpose of
                                fulfilling
                                specific tasks on our behalf and are compelled not to disclose or use your information
                                for any
                                other intent.
                            </p>

                            <h2>Correspondence</h2>
                            <p>We may seek your approval to use your Personal Data to connect with you through
                                newsletters,
                                marketing or promotional materials, and other information that might interest you. You
                                can
                                decline to receive any or all of these communications from us by following the
                                unsubscribe link or
                                instructions provided in any email we send or by reaching out to us.
                            </p>

                            <h2>Adherence To Regulations</h2>
                            <p>We will expose your Personal Data when mandated by law, subpoena, or if we believe that
                                such
                                action is vital to adhere to the law and the rational requests of law enforcement or to
                                protect
                                the security or integrity of our Platform.
                            </p>

                            <h2>Safeguarding</h2>
                            <p>The protection of your Personal Data is crucial to us, and we strive to institute and
                                maintain
                                reasonable, commercially acceptable safety measures and practices befitting the nature
                                of the
                                information we store to shield it from unauthorized access, destruction, use,
                                modification, or
                                disclosure.
                            </p>
                            <p>The connection between the user and the Platform is safeguarded with 128-bit TLS 1.2
                                encryption
                                (HTTPS protocol). Data is stored using our cloud services and platforms.
                            </p>
                            <p>However, please recognize that no means of transmission over the internet or electronic
                                storage
                                is entirely secure, and we cannot ensure the absolute security of the Personal Data we
                                have
                                amassed from you.
                            </p>
                            <p>If the information under our control is jeopardized due to a security breach, we will
                                take all
                                reasonable actions to probe the situation, inform affected individuals, and address the
                                issue in
                                compliance with applicable laws and regulations.
                            </p>

                            <h2>Global Transfer</h2>
                            <p>Your information, including Personal Data, may be transferred to and maintained on
                                computers
                                situated outside your state, province, country, or other governmental jurisdiction where
                                data
                                protection laws may contrast from those in your jurisdiction.
                            </p>
                            <p>If you are located outside the Republic of Tanzania and opt to provide information to us,
                                please
                                note that we transfer the information, including Personal Data, to the Republic of
                                Tanzania and
                                the European Union and process it there.
                            </p>
                            <p>Your consent to this Confidentiality Code, followed by your submission of such
                                information,
                                signifies your agreement to that transfer.
                            </p>
                            <p>Transferring information to other countries is executed in accordance with the protection
                                level
                                mandated by laws or legal recommendations in the Republic of Tanzania and the EU's
                                General Data
                                Protection Regulation (GDPR) effective from 25.05.2018.
                            </p>

                            <h2>References To Different Platforms</h2>
                            <p>Our Platform may contain links to other platforms not operated by us. If you click on a
                                third-party
                                link, you will be directed to that third party's platform. We strongly recommend
                                reviewing the
                                Confidentiality Code of every platform you visit.
                            </p>
                            <p>We have no influence over and presume no accountability for the content, confidentiality
                                codes,
                                or practices of any third-party platforms or services.
                            </p>

                            <h2>Privacy Of Minors</h2>
                            <p>Only individuals aged 18 or older are permitted to access our Platform. Our Platform does
                                not
                                cater to anyone under the age of 16 ("Minors").
                            </p>
                            <p>We do not knowingly amass personally unidentifiable information from Minors under the age
                                of 16.
                                If you are a parent or guardian and learn that your Minors have provided us with
                                Personal Data,
                                please contact us. If we become aware that we have collected Personal Data from Minors
                                under the
                                age of 16 without confirmation of parental consent, we will take steps to eliminate that
                                information from our servers.
                            </p>

                            <h2>Error-Free Functioning</h2>
                            <p>We do not assure error-free functioning within the bounds of this Confidentiality Code.
                                We may
                                not always detect an unintended privacy concern, despite our endeavors to do so. We
                                invite your
                                comments that will aid in enhancing this Confidentiality Code. We will exert reasonable
                                efforts
                                to adhere to this Confidentiality Code and will take swift corrective action when we
                                discover any
                                failure to comply with it.
                            </p>

                            <h2>Judicial Information</h2>
                            <p>This Confidentiality Code is subject to the stipulations and conditions of our Terms of
                                Service
                                Agreement. For a review, visit <a href="http://moncam.net/terms-of-use/">terms of
                                    use</a>.
                                It also incorporates conditions pertaining to restrictions on damages, disclaimers of
                                warranties,
                                and dispute resolution procedures. Our Terms of Service Agreement shall take precedence
                                over any
                                conflicting provisions of this Confidentiality Code.
                            </p>

                            <h2>Revisions To This Confidentiality Code</h2>
                            <p>This Confidentiality Code is effective as of May 25, 2018, and will persist in effect
                                barring any
                                changes in its provisions in the future, which will be effective immediately upon being
                                posted
                                on this page.
                            </p>
                            <p>We retain the right to update or alter our Confidentiality Code at any time, and you
                                should
                                verify this Confidentiality Code periodically. Your sustained use of the Platform after
                                we
                                post any modifications to the Confidentiality Code on this page will constitute your
                                acknowledgment of the modifications and your consent to abide and be bound by the
                                altered
                                Confidentiality Code.
                            </p>
                            <p>If we implement any substantial changes to this Confidentiality Code, we will notify you
                                either
                                through the email address you have provided us or by prominently displaying a notice on
                                our
                                website.
                            </p>

                            <h2>Contact Information</h2>
                            <p className="mb-1">
                                <strong>Email: </strong>
                                <a href="mailto:support@moncam.net">support@moncam.net</a>
                            </p>

                            <p className="mb-0">
                                <strong>Street address:</strong> Plot 838, Namanve Industrial Park Mukono, Uganda
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicyPage;