import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useState} from "react";

const LoginPage = () => {
    const [error, serError] = useState();

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('Email is not valid').required('This field must be filled out'),
        password: Yup.string().required('This field must be filled out'),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        if ((values?.email === 'account47@moncam.net' && values?.password === 'XtdWy9N9GL') ||
            (values?.email === localStorage.getItem("userEmail") && values?.password === localStorage.getItem("password"))) {
            localStorage.setItem("userEmail", values?.email);
            window.location.href = '/account';
        } else {
            serError("Credentials are not valid.")
        }

        setSubmitting(false)
    };

    return (
        <>
            <MetaTags>
                <title>Sign In</title>
            </MetaTags>
            <section id="contact" className="contact-section contact-style-3 hero-section-wrapper-5">
                <div className="container feature-style-5 bg-transparent py-0">
                    <div className="row my-5">
                        <div className="col-12 col-lg-6 m-auto">
                            <div className="single-feature wow fadeInUp h-100 bg-white">
                                <Formik
                                    initialValues={{email: '', password: ''}}
                                    validationSchema={ContactSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({isSubmitting, errors, touched}) => (
                                        <Form>
                                            <h2 className="mb-3 fw-bold">
                                                Sign In
                                            </h2>
                                            <div className="mb-3">
                                                <Field
                                                    fullWidth
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    className='form-custom'
                                                />
                                                {errors.email && touched.email ? (
                                                    <div className='error'>{errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    fullWidth
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    className='form-custom'
                                                    placeholder="Password"
                                                />
                                                {errors.password && touched.password ? (
                                                    <div className='error'>{errors.password}</div>
                                                ) : null}
                                            </div>

                                            {error && <span className="text-danger">
                                                    {error}
                                                </span>}

                                            <button
                                                className="button button-lg radius-50 wow fadeInUp w-100"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? "..." : "Login"}
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginPage;
