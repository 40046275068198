const FooterComponent = () => {

    return (
        <footer className="footer footer-style-4">
            <div className="container">
                <div className="widget-wrapper">
                    <div className="row g-3">
                        <div className="col-12 col-lg-4">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".2s">
                                <div className="logo">
                                    <a href="/">
                                        <img src="assets/images/logo/logo.svg" alt="/"/>
                                    </a>
                                </div>
                                <p className="desc mb-0">
                                    © MonCam.net, All Right Reserved
                                </p>

                            </div>
                        </div>

                        <div className="col-12 col-lg-3">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".4s">
                                <ul className="links">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/contacts">Contacts</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/terms-of-use">Terms of Use</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="footer-widget wow fadeInUp" data-wow-delay=".5s">
                                <h6 className="mb-3">Download App</h6>
                                <div className="download-app d-lg-flex gap-1">
                                    <a href="#" target="_blank">
                                        <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                    </a>
                                    <button disabled>
                                        <img src="assets/images/google.svg"
                                             alt="Get it on Google Play"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default FooterComponent;