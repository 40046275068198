import MetaTags from "react-meta-tags";

const HomePage = () => {
    return (
        <>
            <MetaTags>
                <title>MonCam - Obscured Video Monitoring Innovations</title>
            </MetaTags>

            <section id="home" className="hero-section-wrapper-5">
                {/*background-image: url('assets/images/hero/hero-5/hero-bg.svg')*/}
                <div className="hero-section hero-style-5 img-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-content-wrapper">
                                    <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">Obscured Video Monitoring
                                        Innovations</h2>
                                    <p className="mb-30 wow fadeInUp mb-4" data-wow-delay=".4s">
                                        Explore the transformative realm of cloud-centric surveillance solutions. Reveal
                                        the benefits of distant accessibility, instantaneous notifications, and
                                        expansiveness, positioning cloud-based video monitoring as the imminent
                                        benchmark for all-encompassing security.
                                    </p>
                                    <div className="download-app d-lg-flex gap-1">
                                        <a href="#" target="_blank">
                                            <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                        </a>
                                        <button disabled>
                                            <img src="assets/images/google.svg"
                                                 alt="Get it on Google Play"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 align-self-end">
                                <div className="hero-image wow fadeInUp" data-wow-delay=".5s">
                                    <img src="assets/images/hero/hero-5/hero-img.svg" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="about-section about-style-4 pt-0">
                <div className="container">
                    <div className="row align-items-center g-5">
                        <div className="col-12 col-lg-6 order-1 order-lg-0">
                            <div className="about-image text-lg-right wow fadeInUp" data-wow-delay=".5s">
                                <img src="assets/images/about/about-4/about-img.svg" alt="" className='w-100'/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 order-0 order-lg-1">
                            <div className="about-content-wrapper">
                                <div className="section-title">
                                    <h3 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                                        Introducing the MonCam Cloud Storage Edge
                                    </h3>
                                    <p className="wow fadeInUp mb-3" data-wow-delay=".3s">
                                        MonCam Cloud Storage stands out for its simplicity and user-centric approach,
                                        without any frills or complexities. Curious about why we're the preferred choice
                                        for thousands over competitors? We take pride in our commitment to transparency
                                        and straightforwardness.
                                    </p>

                                    <p className="wow fadeInUp mb-0" data-wow-delay=".3s">
                                        Our technology establishes the benchmark for user-friendliness, ensuring that
                                        every aspect is clear and easily manageable. Combined with budget-friendly
                                        pricing accessible to all, MonCam Cloud Storage leads the market with a
                                        comprehensive offering that excels in the balance between price and quality. Opt
                                        for transparency, excellence, and simplicity—choose MonCam Cloud Storage.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="pricing" className="pricing-section pricing-style-4 bg-light">
                <div className="container">
                    <div className="row align-items-center g-4">
                        <div className="col-xl-6 col-lg-6">
                            <div className="section-title">
                                <h3 className="mb-15 wow fadeInUp" data-wow-delay=".2s">
                                    Continuous Capture at MonCam Cloud Vault
                                </h3>
                                <p className="wow fadeInUp mb-0" data-wow-delay=".4s">
                                    In the realm of MonCam Cloud Storage, time flows seamlessly, mirroring our
                                    uninterrupted recordings. Immerse yourself in the perpetual stream of life, as we
                                    deliver round-the-clock coverage, guaranteeing you won't overlook pivotal moments.
                                    Empowered by cutting-edge motion detection technology, our system flags significant
                                    events, granting effortless retrieval of archived videos.
                                </p>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <img src="assets/images/about-img.webp" alt="" className='w-100'/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="feature" className="feature-section feature-style-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-8">
                            <div className="section-title text-center mb-60">
                                <h3 className="mb-15 wow fadeInUp" data-wow-delay=".2s">
                                    The Pinnacle Cloud Storage Solution
                                </h3>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                    Embrace simplicity in video surveillance with our comprehensive cloud storage
                                    solution. Our app offers a unified platform for monitoring, eliminating the need for
                                    multiple devices or intricate configurations, regardless of your surveillance
                                    equipment's brand.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 d-flex flex-wrap">
                        <div className="col h-100">
                            <div className="single-feature h-100">
                                <div className="icon">
                                    <i className="lni lni-protection"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Confident Control</h5>
                                    <p>
                                        Ensuring Security and Privacy with Secure Control of Your System
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="col h-100">
                            <div className="single-feature h-100">
                                <div className="icon">
                                    <i className="lni lni-cloud-sync"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Smooth Teamwork</h5>
                                    <p>Collaborate Effortlessly with Cloud Video Broadcasting for Teams</p>
                                </div>
                            </div>
                        </div>
                        <div className="col h-100">
                            <div className="single-feature wow fadeInUp h-100" data-wow-delay=".6s">
                                <div className="icon">
                                    <i className="lni lni-alarm"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Timely Awareness</h5>
                                    <p>Receive Instant Alert Notifications for Real-time Awareness</p>
                                </div>
                            </div>
                        </div>
                        <div className="col h-100">
                            <div className="single-feature h-100 " data-wow-delay=".4s">
                                <div className="icon">
                                    <i className="lni lni-calendar"></i>
                                    <svg width="110" height="72" viewBox="0 0 110 72" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                                            fill="#EBF4FF"/>
                                    </svg>
                                </div>
                                <div className="content">
                                    <h5>Extended Tranquility</h5>
                                    <p>Two-Year Extended Assurance for Cloud Storage for Long-Term Peace of Mind</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section id="pricing" className="pricing-section pricing-style-4 bg-light">
                <div className="container">
                    <div className="row align-items-center g-5">
                        <div className="col-12 col-lg-5 text-center">
                            <img src="assets/images/storage.svg" alt="" className=''/>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="section-title">
                                <h3 className="mb-15 wow fadeInUp" data-wow-delay=".2s">
                                    Unbounded Access with MonCam Cloud
                                </h3>
                                <p className="wow fadeInUp mb-3" data-wow-delay=".4s">
                                    Wherever you are, MonCam Cloud Storage transforms recording into a simple
                                    experience. Capture video directly from your MonCam cameras into the cloud—just you,
                                    the camera, and MonCam Cloud Storage create the perfect trio for effortless
                                    recording.
                                </p>
                               <p className="mb-0">
                                   Take command of your recording preferences—decide how you want to record video,
                                   broadcast it, or utilize other features offered by our service. With MonCam, recording
                                   is customized to your preferences and accessible from any location.
                               </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomePage;